<template>
    <div id="content" class="communication">
        <template v-if="form">
            <Form @loadList="load" :id="communicationID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="bar-actions">
                <Button :customClass="{ disabled: !hasAccess }" bType="create" :bCallback="addCommunication" bLabel="communication.create_communication"></Button>
            </div>

            <div class="communication-container">
                <div class="form-container">
                    <div class="communication-title">
                        {{ $t('communication.scheduled_communications') }}
                    </div>
                    <div class="communications" v-if="Object.values(communications.programmed).length > 0">
                        <CommunicationBox @loadList="load" @goToReport="goToReport" v-for="(com, i) in communications.programmed" :type="'programmed'" :key="i" :communication="com"></CommunicationBox>
                    </div>
                    <div class="empty" v-else>{{ $t('communication.empty_scheduled') }}</div>
                </div>
                <div class="form-container right">
                    <div class="communication-title">
                        {{ $t('communication.last_communications') }}
                    </div>
                    <div class="communications" v-if="Object.values(communications.last).length > 0">
                        <CommunicationBox @loadList="load" @goToReport="goToReport" v-for="(com, i) in communications.last" :type="'last'" :key="i" :communication="com"></CommunicationBox>
                    </div>
                    <div class="empty" v-else>{{ $t('communication.empty_last') }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import CommunicationBox from '@/components/domain/logbook/communication/communicationBox'
import Form from '@/components/domain/logbook/communication/form'

export default {
    name: 'Communication',
    props: { id: undefined },
    components: { Tags, CommunicationBox, Form },
    data() {
        return {
            communicationID: undefined,
            form: false
        }
    },
    computed: {
        optionsParents() {
            var parents = Object.values({
                ...this.$store.getters['library/getParentsCategoryFilters']()
            })
            var self = this
            var parentFiltered = parents.filter(function (parent) {
                return parent.id != self.id
            })
            return parentFiltered
        },
        communications() {
            return this.$store.getters['notes/getCommunications']()
        },
        hasAccess() {
            return this.checkPermission('/logbook/communication/add')
        }
    },
    methods: {
        goToReport(id) {
            if (this.checkPermission('/logbook/communication/idCom')) {
                this.form = true
                this.communicationID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('communication.no_permission'),
                    duration: 5000
                })
            }
        },
        addCommunication() {
            if (this.checkPermission('/logbook/communication/add')) {
                this.form = true
                this.communicationID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('communication.no_permission'),
                    duration: 5000
                })
            }
        },
        load() {
            var self = this
            var communications = this.$store.getters['notes/getCommunications']()

            this.$store.dispatch('notes/loadCommunications', {}).then(function () {
                self.$store.dispatch('employee/loadList', {}).then(function () {})
            })

            if (this.$route.params.id) {
                this.goToReport(this.$route.params.id)
            } else if (this.$route.name == 'AddCommunication') {
                this.addCommunication()
            } else {
                this.form = false
            }
        },
        closeForm() {
            this.form = false
            this.communicationID = false
            this.$router.push({ name: 'Communication' })
            this.$overlay.hide()
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content {
    .communication-container {
        @include display-flex();
        padding: 76px 1em 30px 1em;
        gap: 0.5em;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;

        .form-container {
            // @extend .scrollbar;
            // @extend .scrollbar-white;
            margin: 0;
            // margin-left: 40px;
            // padding: 12px;
            padding: 2em 1em;
            width: 50%;
            min-height: unset;
            height: auto;
            max-height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .communication-title {
                @include display-flex();
                @include justify-content();
                @include font-size(lg);
                font-family: $text-bold;
                padding-bottom: 9px;
            }

            .communications {
                @extend .scrollbar;
                @extend .scrollbar-white;
                height: auto;
                max-height: calc(100% - 40px);
            }
            .communication-box {
                background-color: $color-neutral-200;
                padding: 9px;
                margin-bottom: 15px;
            }
        }
    }
}
</style>
