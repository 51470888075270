<template>
    <div id="content" class="communication-form">
        <div class="bar-actions">
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveCommunication" customClass="btn-action" :bLabel="postDate == 'inmediately' ? 'communication.send_communication' : 'communication.schedule_communication'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="deleteCommunication" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container">
            <div class="form">
                <!-- <div class="form-title">
                <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
            </div> -->
                <!-- EMPIEZA todo lo que es el textarea para crear comunicaciones -->
                <div class="new">
                    <div class="error" v-if="this.error">{{ this.error }}</div>
                    <div class="post-as" :class="{ central: post == 2, hasImage: compImage != '' }" :style="{ backgroundImage: 'url(' + employee.avatar + ')' }">
                        <span>{{ $t('notes.post_as') }}</span>
                        <div class="select-container">
                            <v-select v-if="Object.values(postOptions).length > 1" hide-details class="select" v-model="post" :items="Object.values(postOptions)" item-text="name" item-value="id" dense :no-data-text="$t('supervise.filter.no_results')"></v-select>
                            <div class="name" v-if="Object.values(postOptions).length < 2">
                                {{ employee.name + ' ' + employee.surname }}
                            </div>
                        </div>
                    </div>
                    <div class="attachment-reply" v-if="compImage != ''">
                        <div class="message">
                            <div v-if="compImage != ''" @click.stop="preview()" class="img-container">
                                <div
                                    class="img"
                                    :style="{
                                        backgroundImage: 'url(' + (compImage ? compImage : '') + ')',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }"
                                ></div>
                                <div class="delete" @click="imageDelete()"></div>
                            </div>
                        </div>
                    </div>
                    <div class="meta">
                        <div class="attachment" @click="capture($event)">
                            <input type="file" name="change-image" v-if="uploadReady" class="input-file" accept="image/*" capture="camera" @change="imageReload" />
                        </div>

                        <!-- <v-textarea class="message" ref="message" v-model="messageText" flat outlined rows="8" counter="4096" solo hide-details :placeholder="$t('notes.new_note')"> </v-textarea> -->
                        <TranslateInput gType="texteditor" v-model="messageText" :gState="error ? 'error' : ''" :gMessage="{ error: '' }" :gLabel="$t('notes.new_note')" :maxLength="4096" :gOptions="{ actions: ['text', 'italic','list'] }" :inputIcon="false" customClass="communication"></TranslateInput>

                        <!-- <div class="close" @click="reset()"></div> -->
                        <!-- <div class="send" @click="send()"></div> -->
                    </div>
                </div>
                <!-- Acaba el textarea -->
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form location">{{ $t('communication.location') }}</h2>
                        <div class="box-container">
                            <div class="column-description" @click="sites = 'allLocals'">
                                <div class="box-info">
                                    <div class="select-role" :class="{ selected: sites == 'allLocals' }"></div>
                                    <div class="box-name">{{ $t('communication.all_locations') }}</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.localization_desc') }}
                                </div>
                            </div>
                        </div>
                        <div class="box-container">
                            <div class="column-description" @click="sites = 'exclusive'">
                                <div class="box-info">
                                    <div class="select-role" :class="{ selected: sites == 'exclusive' }"></div>
                                    <div class="box-name">{{ $t('communication.exclusive_in') }}</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.group_desc') }}
                                </div>
                            </div>

                            <p v-if="emptyAnswer.emptySite" class="error-form error-field" v-html="$t('external_tools.general_error')"></p>
                            <SiteLimit @setSiteLimit="setSiteLimit" v-if="sites == 'exclusive'" :locations="locationsSave" :groups="groupsSave"></SiteLimit>
                            <div class="must" v-if="sites == 'exclusive'" v-html="$t('communication.exclusive_desc_must')"></div>
                        </div>
                    </div>
                    <div class="right-container">
                        <h2 class="label-form date">{{ $t('communication.publication_date') }}</h2>

                        <div class="box-container">
                            <div class="column-description" @click="postDate = 'inmediately'">
                                <div class="box-info">
                                    <div class="select-role" :class="{ selected: postDate == 'inmediately' }"></div>
                                    <div class="box-name">{{ $t('communication.inmediately') }}</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.localization_desc') }}
                                </div>
                            </div>
                        </div>

                        <div class="box-container">
                            <div class="column-description" @click="postDate = 'scheduled'">
                                <div class="box-info">
                                    <div class="select-role" :class="{ selected: postDate == 'scheduled' }"></div>
                                    <div class="box-name">{{ $t('communication.schedule_date') }}</div>
                                </div>
                                <div class="box-description">
                                    {{ $t('user.form.group_desc') }}
                                </div>
                            </div>

                            <div class="date-picker" v-if="postDate == 'scheduled'">
                                <p v-if="emptyAnswer.business_date" class="error-form error-field" v-html="$t('external_tools.general_error')"></p>
                                <input type="datetime-local" :min="today" v-model="businessDate" class="input-datepicker" />
                                <div class="required">{{ $t('signup.form.error.required_field') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="inline-delete" v-if="$route.params.id">
            <span class="text" @click="deleteCommunication()">{{ $t('communication.remove_communication') }}</span>
        </div> -->
    </div>
</template>

<script>
import i18n from '@/i18n'
import SiteLimit from '@/components/ui/SiteLimit'

export default {
    name: 'CommunicationManagement',
    props: { id: undefined },
    components: { SiteLimit },
    data() {
        return {
            date: moment(),
            messageText: '',
            image: '',
            error: '',
            compImage: '',
            uploadReady: true,
            post: 2,
            sendForm: true,
            form: false,
            limitLocations: false,
            sites: 'allLocals',
            postDate: 'inmediately',
            groupsSave: '',
            locationsSave: '',
            businessDate: '',
            today: moment().format('YYYY-MM-DDTHH:mm'),
            emptyAnswer: {
                business_date: false,
                emptySite: false
            },
            moreOptions: false
        }
    },
    computed: {
        employee() {
            var employeeId = this.$store.getters['loginUser/getLocalEmployee']
            if (employeeId && this.employees) {
                return this.employees[employeeId]
            }
            return false
        },
        employees() {
            return this.$store.getters['employee/getAllEmployees']
        },
        postOptions() {
            var result = []
            if (this.locationData && this.employee) {
                var roles = typeof localStorage.roles !== 'undefined' ? JSON.parse(localStorage.roles) : {}
                var user = typeof localStorage.user !== 'undefined' ? JSON.parse(localStorage.user) : {}

                if (user.rol && typeof roles[user.rol] !== 'undefined' && parseInt(roles[user.rol].level) >= 6) {
                    result.push({ name: this.account.account_name ? this.account.account_name : this.locationData.name, id: 2 })
                }
                result.push({ name: this.employee.name + ' ' + this.employee.surname, id: 1 })
            }
            return result
        },
        locationData() {
            return this.$store.getters['login/getLocalLocation']
        },
        account() {
            return this.$store.getters['login/getLocalEmployee']
        },
        locations() {
            return this.$store.getters['location/getListTable']()
        },
        groups() {
            return this.$store.getters['groups/getList']()
        }
    },
    methods: {
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        // Metodos para el funcionamiento del textarea
        capture(e, type) {
            if (process.env.VUE_APP_IS_APP == 'TRUE') {
                e.preventDefault()
                e.stopPropagation()
                var self = this

                var cameraOptions = {
                    destinationType: 0,
                    quality: 70,
                    targetWidth: 1200,
                    targetHeight: 1200,
                    correctOrientation: true
                }

                this.$popup.confirmUpload({
                    title: this.$t('cordova.plugins.camera.title'),
                    textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                    textSave: this.$t('cordova.plugins.camera.text_library'),
                    callSaveStart: function () {
                        navigator.camera.getPicture(
                            function (imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()
                                bgImg.onload = function () {
                                    self.compImage = bgImg.src
                                }
                                bgImg.src = imageData
                            },
                            function (message) {
                                // console.log(message)
                            },
                            cameraOptions
                        )
                    },
                    callSave: function () {
                        cameraOptions.sourceType = 0

                        navigator.camera.getPicture(
                            function (imageData) {
                                imageData = 'data:image/jpeg;base64,' + imageData
                                var bgImg = new Image()
                                bgImg.onload = function () {
                                    self.compImage = bgImg.src
                                }
                                bgImg.src = imageData
                            },
                            function (message) {
                                // console.log(message)
                            },
                            cameraOptions
                        )
                        self.error = ''
                    }
                })
            } else {
                this.imageReload(e)
            }
        },
        imageDelete() {
            let self = this
            this.$popup.confirm({
                message: self.$t('popup.delete.title'),
                textSave: self.$t('popup.delete.text_save'),
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function () {
                    self.compImage = ''
                }
            })
        },
        imageReload(e) {
            // console.log('entra', e)
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                self.compImage = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.error = ''
        },
        preview() {
            if (this.compImage) {
                this.$popup.media({ image: this.compImage })
            }
        },
        // Hasta aquí son los metodos necesarios para el textarea
        popupStatus() {
            var selfThis = this
            if (selfThis.statusProductState == '1' || selfThis.statusProductState == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('product.product_state.popup_inactive'),
                    textSave: selfThis.$t('product.product_state.state_save'),
                    callSave: function () {
                        selfThis.statusProductState = false
                    },
                    callCancel: function () {
                        selfThis.statusProductState = true
                    }
                })
            } else {
                selfThis.statusProductState = true
            }
        },

        load() {
            if (this.$route.name == 'Communication') {
                if (typeof this.id != 'undefined') {
                    this.$router.push({ name: 'EditCommunication', params: { id: this.id } })
                    var editCom = this.$store.getters['notes/getCommunications'](this.id)
                    // console.log(editCom)
                    if (editCom) {
                        this.messageText = editCom.message
                        this.compImage = editCom.image
                        if (editCom.image == null) {
                            this.compImage = ''
                        }
                        if (editCom.groups.length > 0) {
                            this.groupsSave = editCom.groups.toString()
                            this.sites = 'exclusive'
                        }
                        if (editCom.locations.length > 0) {
                            this.locationsSave = editCom.locations.toString()
                            this.sites = 'exclusive'
                        }
                        if (editCom.bussines_date) {
                            this.postDate = 'scheduled'
                            this.businessDate = moment(editCom.bussines_date * 1000).format('YYYY-MM-DDTHH:mm')
                        }
                    }
                } else {
                    this.$router.push({ name: 'AddCommunication' })
                }
            }
        },
        attachImage(self, id, image, goDetail) {
            // console.log(id, image)
            var oData = { id: id, image: image }
            self.$store.dispatch('notes/attachImage', oData).then(function (response) {})
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },
        saveCommunication() {
            var self = this
            self.$overlay.loading()
            this.sendForm = true
            var addImage = false
            var communication = {}
            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
            if (this.messageText == '' && this.compImage == '') {
                this.error = this.$t('notes.message_validation')
                this.sendForm = false
            }
            communication.message = this.messageText
            if (this.compImage != '') {
                if (this.compImage.indexOf('data:') != -1 && this.compImage.indexOf(';base64,') != -1) {
                    addImage = true
                }
                communication.image = this.compImage
            }
            if (this.businessDate != '') {
                communication.bussines_date = moment(this.businessDate).format('YYYY-MM-DD HH:mm')
            } else {
                communication.bussines_date = ''
            }
            if (this.postDate == 'scheduled') {
                if (this.businessDate == '') {
                    this.sendForm = false
                    this.emptyAnswer.business_date = true
                }
            }

            if (this.postDate == 'inmediately') {
                communication.bussines_date = ''
            }

            if (this.sites == 'exclusive') {
                if (this.locationsSave != '') {
                    communication.locations = this.locationsSave
                } else if (this.groupsSave != '') {
                    communication.groups = this.groupsSave
                } else if (this.groupsSave == '' && this.locationsSave == '') {
                    this.emptyAnswer.emptySite = true
                    this.sendForm = false
                }
            } else {
                communication.groups = ''
                communication.locations = ''
            }

            if (this.post == 1) {
                communication.employee_id = this.employee.id
                communication.type = 1
            }

            if (this.sendForm) {
                if (this.$route.params.id) {
                    communication.id = this.$route.params.id
                    this.$store.dispatch('notes/editCommunication', communication).then(function (response) {
                        if (addImage) {
                            self.attachImage(self, response.data.id, self.compImage)
                        }
                        setTimeout(function () {
                            self.loadList()
                            self.goBack()
                            self.$snackbar.open({ message: self.$t('settings.edit_success'), customClass: 'success', duration: 5000 })
                        }, 500)
                    })
                } else {
                    this.$store.dispatch('notes/addCommunication', communication).then(function (response) {
                        if (addImage) {
                            self.attachImage(self, response.data.id, self.compImage)
                        }
                        setTimeout(function () {
                            self.loadList()
                            self.goBack()
                            self.$snackbar.open({ message: self.$t('settings.edit_success'), customClass: 'success', duration: 5000 })
                        }, 500)
                    })
                }
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },
        setSiteLimit(data) {
            this.groupsSave = data.groups
            this.locationsSave = data.locations
        },
        deleteCommunication() {
            var self = this
            self.$popup.confirm({
                message: self.$t('communication.popup_delete'),
                textSave: self.$t('user.popup.delete_text_save'),
                textCancel: self.$t('user.popup.delete_text_cancel'),
                callSave: function () {
                    self.$store.dispatch('notes/removeCommunication', self.id).then(function (response) {
                        self.loadList()
                        self.$emit('back')
                        self.$snackbar.open({ message: self.$t('snackbar.remove_success'), customClass: 'second', duration: 5000 })
                    })
                }
            })
        },
        loadList() {
            this.$emit('loadList')
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss" scoped>
$note-img-size: 40px;
$note-img-size-w: 40px;
$width_send_note: 40px;
$width_close: 12px;
#content.communication-form {
    width: 100%;
    height: 100%;
    .bar-actions {
        margin-bottom: 60px;
    }
    .form-container {
        height: 90%;

        .label-container {
            padding-top: 12px;

            .left-container,
            .right-container {
                display: flex;
                flex-direction: column;
                gap: 1em;
            }

            .label-form {
                &.location {
                    @include background($image: img('location_dark.svg'), $position: left top 2px, $size: 12px);
                    padding-left: 15px;
                }
                &.date {
                    @include background($image: img('input_date_neutro_s90.svg'), $position: left top 2px, $size: 12px);
                    padding-left: 18px;
                }
            }

            .box-container {
                @include background($color: $color-neutral-200);
                @include display-flex();
                @include flex-wrap(wrap);
                width: 100%;
                height: auto;
                padding: 10px;
                border-radius: 4px;

                &.hasRole {
                    width: unset !important;
                }

                .input-role {
                    width: 18px;
                }
                .input-role:checked {
                    background-color: red;
                }
                .box-name {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $color-black;
                    padding-right: 4px;
                }
                .default-name {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $color-black;
                }
                .have-description {
                    @include display-flex();
                    @include flex-direction(column);
                    width: 100%;
                }
                .no-role {
                    @include display-flex();
                    @include flex-direction(column);
                    width: 50%;
                    height: auto;
                    padding: 10px;
                    cursor: pointer;
                }
                .box-info {
                    @include display-flex();

                    .select-role {
                        cursor: pointer;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        border: 2px solid $color-neutral-600;
                        margin-right: 6px;
                        &.selected {
                            @include background($image: img('checked_second.svg'));
                            border: none;
                        }
                    }
                }
                .box-description {
                    @include font-size(xs);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                    padding-left: 24px;
                }
            }
            .site-limit {
                margin-top: 15px;
                // align-items: center;
            }
            .must {
                @include font-size(xs);
                font-family: $text;
                color: $color-neutral-600;

                .bolder {
                    @include font-size(xs);
                    font-family: $text-bold;
                    color: $color-neutral-600;
                }
            }
            .input-datepicker {
                margin-top: 15px;
            }
            .required {
                @include font-size(xs);
                font-family: $text-bold;
                color: $color-neutral-600;
                padding-top: 5px;
            }
        }

        .error {
            @include background($color: $color-error-100);
            padding: 10px 15px;
            color: $color-error-800;
        }

        // TEXTAREA
        .post-as {
            @include display-flex();
            @include align-items(center);
            padding-left: 40px;
            height: 48px;
            position: relative;
            background-position: left 5px center;
            background-repeat: no-repeat;
            background-size: 35px;
            width: 100%;

            span {
                font-family: $text-bold;
                @include font-size(sm);
                margin-right: 6px;
                color: $color-black;
                margin-left: 5px;
            }
            .select-container {
                width: 200px;
            }

            &.central {
                @include background($image: img('avatar_main.svg') !important, $position: left 5px center !important, $size: 35px);
            }
            &.hasImage {
                height: 45px;
                width: 100%;
            }
        }

        .new {
            @include background($color: #fff);
            @include border-radius(4px);
            display: block;
            margin: 0;
            min-height: 60px;
            width: calc(100% - 24px);
            padding-bottom: 2em;

            &.active {
                border-color: $color-primary-100;
            }

            .error {
                @include background($color: $color-error-100);
                padding: 10px 15px;
                color: $color-error-800;
            }

            .editing {
                width: 100%;
                display: block;
                flex: 1;
                padding: 5px 10px;
                @include background($color: $color-primary-100);
                @include display-flex();
                margin-bottom: 5px;
                @include border-radius(4px);

                &:before {
                    @include background($image: img('edit_dark.svg'), $size: 13px, $position: 4px 10px);
                    content: '';
                    width: 35px;
                    height: 35px;
                    display: inline-block;
                    margin-right: 0px;
                }
                .message {
                    @include font-size(xs);
                    font-family: $text-bold;
                    margin: 0;
                    width: 100%;
                    color: $color-primary-500;
                    padding-top: 6px;
                    @include text-ellipsis();
                }

                .close {
                    @extend .interaction;
                    @include background($image: img('close_main_s20.svg'), $size: 12px, $color: $color-primary-100);
                    @include border-radius(100%);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    margin: 0;
                    margin-left: 10px;

                    &:hover {
                        background-color: hover($color-primary-100);
                    }
                }
            }
            .attachment-reply {
                @include border-radius(4px);
                @include display-flex();
                @include background($color: #fff);
                border-top: 1px solid $color-neutral-200;
                border-right: 1px solid $color-neutral-200;
                border-left: 1px solid $color-neutral-200;
                width: 100%;
                display: block;
                padding: 5px 10px;
                height: 67px;
                padding-top: 5px;

                .img-container {
                    @include display-flex();
                    @include border-radius(2px);
                    background-repeat: no-repeat;
                    width: 50px;
                    height: 50px;
                    margin-top: 2px;

                    .img {
                        @include border-radius(2px);
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 50px;
                        height: 50px;
                        display: block;
                    }

                    .delete {
                        @extend .interaction;
                        @include background($image: img('close_main_ffffff.svg'), $size: 9px, $color: $color-black, $position: 3px 3px);
                        @include border-radius(100%);
                        min-width: 15px;
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        margin: 0;
                        margin-left: -11px;
                        margin-top: -3px;

                        &:hover {
                            background-color: hover($color-black);
                        }
                    }
                }
            }

            .meta {
                @include display-flex();
                @include align-items();
                @include justify-content();
                gap: 4px;
                margin-top: 1em;
                padding: 10px;
                border: 1px solid $color-neutral-200;

                .attachment {
                    @extend .interaction;
                    @include background($color: $color-neutral-200, $image: img('attach_dark.svg'), $size: 20px);
                    @include border-radius(3px);
                    width: $note-img-size !important;
                    height: $note-img-size !important;

                    &:hover {
                        background-color: hover($color-neutral-200);
                    }
                }

                .input-file {
                    @extend .interaction;
                    opacity: 0;
                    z-index: 1;
                }

                .message {
                    @include background($color: $color-neutral-200);
                    width: calc(70% - 140px);
                    border: none;
                    color: $color-black;
                    line-height: 18px;
                    height: auto;
                    // max-height: 60px;
                    overflow-y: auto;
                    margin-left: 10px !important;

                    &::placeholder {
                        padding-top: 10px;
                        font-style: normal;
                        color: $color-neutral-600;
                    }
                }

                .close {
                    @extend .interaction;
                    @include background($image: img('close_main_neutro_dark.svg'));
                    @include align-self(center);
                    width: $width_close;
                    height: 12px;
                    margin-left: 4px;
                    margin-right: 16px;

                    &:hover {
                        background-image: img('close_main_s20.svg');
                    }
                }

                .send {
                    @extend .interaction;
                    @include background($image: img('send_light.svg'), $color: $color-primary-500, $size: 22px);
                    @include border-radius(50%);
                    width: $width_send_note;
                    height: 40px;

                    &:hover {
                        background-color: hover($color-primary-500);
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.form-container {
    display: grid;
    justify-items: center;
    padding-top: 3em;

    .new {
        .message {
            overflow: auto;

            textarea {
                margin: 0 !important;
                margin-top: 0px !important;
                min-height: 30px;
                padding: 0px;
                border: 0;
                padding-top: 5px !important;
                font-style: normal;
                background-color: transparent;
                height: 100% !important;

                &:focus,
                &:hover {
                    border: 0;
                }
            }
            .v-input__slot {
                background-color: transparent !important;
            }
        }
    }
}
</style>
