<template>
    <div class="communication-box" v-if="communication">
        <div class="icon-container">
            <div class="icon"></div>
        </div>
        <div class="text-container">
            <div class="user-info">
                <div class="employee-name" v-if="communication.employee_id">{{ employees[communication.employee_id] ? employees[communication.employee_id].name : 'Vamonos con pizza hut' }}</div>
                <div class="employee-name" v-else>{{ locationData.account_name }}</div>
                <div class="date" :class="{ last: type == 'last' }">{{ goodFormatTime(communication.bussines_date) }}</div>
            </div>
            <div class="message" v-html="msgParse(communication.message)"></div>
            <div v-if="communication.image" @click.stop="preview()">
                <cld-image class="image" :publicId="communication.image" type="fetch" loading="lazy">
                    <cld-transformation height="100" radius="3" crop="fill" fetchFormat="auto" />
                </cld-image>
            </div>
            <div class="location">
                <div class="text">{{ $t('communication.location') }}</div>
                <div class="all-locations" v-if="locationsCheck()">
                    {{ $t('communication.all_locations') }}
                </div>
                <div class="tags" v-else>
                    <Tags :tags="arrayLoc(communication)" :nameTag="'name'" value="id"></Tags>
                </div>
            </div>
        </div>
        <div class="actions" :class="{ opened: actionOpened }" @click.stop="actions()">
            <template v-if="actionOpened">
                <div v-if="checkPermission('delete')" class="ico delete" @click="deleteCommunication()"></div>
                <div v-if="checkPermission('edit')" class="ico edit" @click="edit()"></div>
                <div class="ico cancel" @click.stop="actions()"></div>
            </template>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'CommunicationBox',
    props: { communication: undefined, type: undefined },
    components: { Tags },
    data() {
        return {
            actionOpened: false
        }
    },
    computed: {
        employees() {
            return this.$store.getters['employee/getAllEmployees']
        },
        locations() {
            return this.$store.getters['location/getListTable']()
        },
        groups() {
            return this.$store.getters['groups/getList']()
        },
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        }
    },
    methods: {
        msgParse(msg) {
            var result = msg
            result = result.replace(/\n/gi, ' <br> ')
            result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
                var hyperlink = url.startsWith('www') ? 'https://' + url : url
                return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
            })
            return result
        },

        load() {},
        edit() {
            // console.log('hola', this.communication)
            this.$emit('goToReport', this.communication.id)
        },
        remove() {},
        goodFormatTime(time) {
            return moment(time * 1000).format('DD MMM YYYY') + ' ' + this.$t('communication.at') + ' ' + moment(time * 1000).format('HH:mm')
        },
        locationsCheck() {
            if (this.communication.locations.length == 0 && this.communication.locations.length == 0) {
                return true
            }
            return false
        },
        loadList() {
            this.$emit('loadList')
        },
        deleteCommunication() {
            var self = this
            self.$popup.confirm({
                message: self.$t('communication.popup_delete'),
                textSave: self.$t('user.popup.delete_text_save'),
                textCancel: self.$t('user.popup.delete_text_cancel'),
                callSave: function () {
                    self.$store.dispatch('notes/removeCommunication', self.communication.id).then(function (response) {
                        self.loadList()
                        self.$emit('back')
                        self.$snackbar.open({ message: self.$t('snackbar.remove_success'), customClass: 'second', duration: 5000 })
                    })
                }
            })
        },
        checkPermission(action) {
            return true
        },
        actions() {
            this.actionOpened = !this.actionOpened
        },
        arrayLoc(com) {
            var locations = com.locations
            var groups = com.groups
            if (locations.length > 0) {
                var arrLocations = []
                for (var loc in locations) {
                    var idx = locations[loc]
                    if (this.locations[idx]) {
                        arrLocations.push(this.locations[idx])
                    }
                }
                return arrLocations
            } else {
                var arrGroups = []
                for (var grp in groups) {
                    var idx = groups[grp]
                    if (this.groups[idx]) {
                        arrGroups.push(this.groups[idx])
                    }
                }
                return arrGroups
            }
            return false
        }
    },
    created() {},
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
.communication-box {
    @include display-flex();
    @include border-radius(4px);
    background-color: $color-neutral-200;
    padding: 9px;
    margin-bottom: 15px;
    width: 100%;

    b{
        font-family: $text-bold;
    }

    u{
        text-decoration: underline;
    }

    ul{
        list-style:circle;
        padding-left: 30px;
    }

    ol{
        list-style:decimal;
        padding-left: 30px;
    }

    .icon-container {
        width: 40px;
        height: 100%;
        min-height: 60px;

        .icon {
            @include background($color: $color-primary-500, $image: img('megaphone_ffffff.svg'), $size: 30px);
            @include border-radius(50%);
            @include align-self(flex-start);
            width: 40px;
            height: 40px;
        }
    }
    .image {
        margin-top: 6px;
    }
    .text-container {
        width: calc(100% - 160px);
        padding-left: 6px;

        .user-info {
            @include display-flex();
            @include align-items(center);

            .employee-name {
                @include font-size(ml);
                font-family: $text-bold;
                padding-right: 5px;
                color: $color-black;
            }
            .date {
                @include font-size(xs);
                font-family: $text-bold;
                color: $color-primary-500;
                &.last {
                    @include font-size(xs);
                    font-family: $text;
                    color: $color-neutral-600;
                }
            }
        }
        .message {
            @include font-size(md);
            font-family: $text-medium;
            color: $color-black;
            padding-top: 3px;
        }
        .location {
            padding-top: 12px;
            .text {
                @include background($image: img('location_dark.svg'), $position: left center, $size: 10px);
                @include font-size(sm);
                font-family: $text-bold;
                color: $color-black;
                padding-left: 12px;
            }
            .all-locations {
                @include font-size(xs);
                padding-top: 3px;
                font-family: $text-medium;
                color: $color-neutral-600;
                padding-left: 14px;
            }
            .tags {
                padding-left: 14px;
            }
        }
    }
    .actions {
        @include display-inline-flex();
        @include border-radius(6px);
        @include background($image: img('more_vert_aaaaaa.svg'), $position: right center, $size: 20px);
        @include justify-content(flex-end);
        @include align-items(flex-end);
        max-height: 40px;
        min-width: 100px;
        cursor: pointer;

        .ico {
            @extend .ico;
            background-size: 18px;

            &.edit {
                background-image: img('edit_dark.svg');
            }
            &.delete {
                background-image: img('delete_alert.svg');
            }
            &.cancel {
                background-image: img('more_vert_aaaaaa.svg');
                background-size: 20px;
                width: 30px;
                max-height: 40px;
                min-width: 40px;
            }
        }

        &.opened {
            @include display-flex();
            margin-right: 10px;
            background-color: $color-neutral-200;
            background-image: unset;
            box-shadow: 0px 0px 4px rgba(88, 87, 87, 0.08);
        }
    }
}
</style>
